const EditLinkWrapper = ({ additionalStyles, children }) => {
  return (
    <div
      className={`mt-4 lg:w-[80%] flex items-center justify-center w-full ${additionalStyles}`}
    >
      {children}
    </div>
  );
};

export default EditLinkWrapper;
