const CardHeader = ({ children }) => {
  return (
    <div
      className="w-full flex items-center justify-between xlg:gap-4 gap-2"
      style={{ height: "clamp(20px, 3vw, 30px)" }}
    >
      {children}
    </div>
  );
};

export default CardHeader;
