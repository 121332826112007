import { useDrawerContext } from "../../../context/DrawerContext/DrawerContext";
import URLCard from "./URLCard";
import { MyURLLogo, LockedLogo, SearchIcon } from "./Logos";
import { useAuthContext } from "../../../context/AuthContext/AuthContext";
import { useState } from "react";
import { useEffect } from "react";
import { Skeleton, Spinner } from "@chakra-ui/react";
import StyledDrawer from "./components/URLDrawerComponents/StyledDrawer";
import DrawerItemList from "./components/URLDrawerComponents/DrawerItemList";
import DrawerCloseButtonMobile from "./components/URLDrawerComponents/DrawerCloseButtonMobile";
import DrawerHeaderWrapper from "./components/URLDrawerComponents/DrawerHeaderWrapper";
import NonAuthWarning from "./components/URLDrawerComponents/NonAuthWarning";
import SearchBarWrapper from "./components/URLDrawerComponents/SearchBarWrapper";
import DrawerSearchBar from "./components/URLDrawerComponents/DrawerSearchBar";
import { useMixpanelClient } from "../../../hooks/useMixpanelClient";
import { RistekAds } from "@ristek-kit/ads";

const URLDrawer = () => {
  const [searchedLink, setSearchedLink] = useState("");
  const { user, loggingIn } = useAuthContext();
  const { drawerOpened, setDrawerOpened, urls, loadingUrls } =
    useDrawerContext();
  const [currentUrls, setCurrentUrls] = useState(urls);

  useEffect(() => {
    setCurrentUrls(urls);
  }, [urls]);

  const checkUrl = (url, str) => {
    const pattern = str
      .split("")
      .map((x) => {
        return `(?=.*${x})`;
      })
      .join("");

    const regex = new RegExp(`${pattern}`, "g");

    return url.match(regex);
  };

  const handleChange = (e) => {
    setSearchedLink(e.currentTarget.value);
    const subSearchedLink = searchedLink.toLowerCase().substring(0, 3);
    const filtered = urls.filter((url) => {
      const urlSub = url.shorten.substring(0, 3).toLowerCase();
      return (
        url.shorten.toLowerCase().includes(subSearchedLink) ||
        checkUrl(urlSub, subSearchedLink)
      );
    });

    setCurrentUrls(filtered);
  };

  return (
    <StyledDrawer open={drawerOpened}>
      <DrawerCloseButtonMobile
        onClick={() => {
          setDrawerOpened(!drawerOpened);
          useMixpanelClient.track("close_my_urls", {
            is_logged_in: user ? "True" : "False",
          });
        }}
      >
        &times;
      </DrawerCloseButtonMobile>
      <div className="px-4">
        <RistekAds className="mb-5" />
      </div>
      <DrawerHeaderWrapper>
        <MyURLLogo />
        <h3 className="font-bold text-3xl ml-3">My URLs</h3>
      </DrawerHeaderWrapper>
      {loggingIn ? (
        <Skeleton margin="1rem" height="750px" borderRadius="10px" />
      ) : (
        <DrawerItemList>
          {!user ? (
            <NonAuthWarning>
              <LockedLogo size={55} />
              <div className="ml-[0.8rem]">
                <h2 className="text-[18px] font-bold">
                  Unlock access to more features
                </h2>
                <p align="left" className="block">
                  Your URL is not saved when the page is refreshed. Sign in to
                  save, edit, and track your URL clicks for free.
                </p>
              </div>
            </NonAuthWarning>
          ) : (
            <SearchBarWrapper>
              <DrawerSearchBar
                onKeyUp={handleChange}
                onChange={handleChange}
                placeholder="Search your short link"
              />
              <SearchIcon size={20} />
            </SearchBarWrapper>
          )}
          {!loadingUrls && currentUrls.length ? (
            currentUrls.map((data) => (
              <URLCard data={data} key={data.shorten} />
            ))
          ) : searchedLink.length ? (
            <h2
              style={{
                fontWeight: "bold",
                fontSize: "clamp(14px, 2vw, 20px)",
              }}
            >
              URL with queried short link was not found.
            </h2>
          ) : (
            !loadingUrls && (
              <h2
                style={{
                  fontWeight: "bold",
                  fontSize: "clamp(14px, 2vw, 20px)",
                }}
              >
                You don&apos;t have any URLs yet.
              </h2>
            )
          )}
          {loadingUrls && <Spinner />}
        </DrawerItemList>
      )}
    </StyledDrawer>
  );
};

export default URLDrawer;
