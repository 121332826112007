const VisitorCountWrapper = ({ children }) => {
  return (
    <div
      style={{ margin: "clamp(7px, 1vw, 10px) 0" }}
      className="grow box-border w-full flex justify-start h-fit"
    >
      {children}
    </div>
  );
};

export default VisitorCountWrapper;
