const WarningIcon = ({ size, color }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.1665 5.83329H10.8332V7.49996H9.1665V5.83329Z"
        fill={color || "#002022"}
      />
      <path
        d="M9.1665 9.16663H10.8332V14.1666H9.1665V9.16663Z"
        fill={color || "#002022"}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.6665 9.99996C1.6665 5.39996 5.39982 1.66663 9.99984 1.66663C14.5999 1.66663 18.3332 5.39996 18.3332 9.99996C18.3332 14.6 14.5999 18.3333 9.99984 18.3333C5.39982 18.3333 1.6665 14.6 1.6665 9.99996ZM3.33317 9.99996C3.33317 13.675 6.32481 16.6666 9.99984 16.6666C13.6749 16.6666 16.6665 13.675 16.6665 9.99996C16.6665 6.32496 13.6749 3.33329 9.99984 3.33329C6.32481 3.33329 3.33317 6.32496 3.33317 9.99996Z"
        fill={color || "#002022"}
      />
    </svg>
  );
};

export default WarningIcon;
