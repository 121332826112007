import Link from "next/link";
import React from "react";

const STYLE_GUIDE = {
  primary: {
    border: "rounded-lg",
    width: "border-2",
    color: "bg-primaryPurple-active",
    hover: "bg-primaryPurple-hover",
    text: "text-white",
    borderColor: "border-primaryPurple-active",
  },
  secondary: {
    border: "rounded-lg",
    width: "border-2",
    color: "bg-primaryWhite",
    hover: "bg-primaryAlabaster",
    text: "text-primaryPurple-active",
    borderColor: "border-primaryPurple-active",
  },
  outlined: {
    border: "rounded-lg",
    width: "border-2",
    color: "bg-primaryAlabaster",
    hover: "",
    text: "text-primaryPurple-active",
    borderColor: "border-primaryPurple-active",
  },
  warning: {
    border: "rounded-lg",
    width: "border-2",
    color: "bg-primaryWhite",
    hover: "bg-primaryAlabaster",
    text: "text-red-500",
    borderColor: "border-red-500",
  },
  ghost: {
    border: "rounded-lg",
    width: "border-0",
    color: "",
    text: "text-primaryPurple-active",
    hover: "bg-primaryAlabaster",
    borderColor: "",
  },
  submitted: {
    border: "rounded-lg",
    width: "border-2",
    color: "bg-submitted",
    hover: "",
    text: "text-white",
    borderColor: "border-submitted",
  },
  stale: {
    border: "rounded-lg",
    width: "border-2",
    color: "bg-gray-400",
    hover: "",
    text: "text-white",
    borderColor: "border-gray-400",
  },
  active: {
    border: "rounded-lg",
    width: "border-2",
    color: "bg-violet-200",
    hover: "",
    text: "text-[#5038BC]",
    borderColor: "border-violet-200",
  },
  cancel: {
    border: "rounded-lg",
    width: "border-2",
    color: "bg-red-200",
    hover: "",
    text: "text-[#EB5757]",
    borderColor: "border-red-200",
  },
  dead: {
    border: "rounded-lg",
    width: "border-2",
    color: "bg-gray-200",
    hover: "",
    text: "text-gray-400",
    borderColor: "border-gray",
  },
  activeLink: {
    border: "",
    width: "",
    color: "",
    hover: "",
    text: "text-[#5038BC]",
    borderColor: "border-[#5038BC]",
  },
};

export const DeadButton = ({
  type,
  color,
  hover,
  children,
  additionStyle,
  ...props
}) => {
  return (
    <div
      className={`md:text-[1em] text-[0.8em] ${STYLE_GUIDE[type].text} ${STYLE_GUIDE[type].borderColor} font-semibold ${STYLE_GUIDE[type].border} ${STYLE_GUIDE[type].width} hover:${STYLE_GUIDE[type].hover} ${STYLE_GUIDE[type].color} md:pl-6 md:pr-6 md:pt-2.5 md:pb-2.5 pr-4 pb-3 pl-4 pt-3  ${additionStyle}`}
    >
      {children}
    </div>
  );
};

const Button = ({
  type,
  color,
  hover,
  children,
  additionStyle,
  to,
  onClick,
  action,
  ...props
}) => {
  if (to) {
    return (
      <Link href={to}>
        <button
          className={`md:text-base text-sm ${STYLE_GUIDE[type].text} ${STYLE_GUIDE[type].borderColor} font-semibold ${STYLE_GUIDE[type].border} ${STYLE_GUIDE[type].width} hover:${STYLE_GUIDE[type].hover} ${STYLE_GUIDE[type].color} md:pl-6 md:pr-6 md:pt-4 md:pb-4 pr-4 pb-3 pl-4 pt-3  ${additionStyle}`}
          {...props}
        >
          {children}
        </button>
      </Link>
    );
  }
  return (
    <button
      type={action}
      onClick={onClick}
      className={`h-full md:text-[1em] text-[0.8em] flex items-center justify-center ${
        STYLE_GUIDE[type].text
      } ${STYLE_GUIDE[type].borderColor} font-semibold ${
        STYLE_GUIDE[type].border
      } ${STYLE_GUIDE[type].width} hover:${STYLE_GUIDE[type].hover} ${
        STYLE_GUIDE[type].color
      }  md:pl-6 md:pr-6 md:pt-2.5 md:pb-2.5 pr-4 pb-3 pl-4 pt-3 ${additionStyle} ${
        type == "activeLink"
          ? "flex flex-col after:content-[''] after:h-[4px] after:w-full after:bg-[#5038BC] after:rounded-md gap-2"
          : ""
      }`}
      {...props}
    >
      {children}
    </button>
  );
};

export default Button;
