const DrawerCloseButtonMobile = ({ onClick, children }) => {
  return (
    <div
      onClick={onClick}
      className="cursor-pointer absolute h-[60px] w-[60px] text-[3em] top-0 left-[calc(100vw-4rem)] text-black justify-center items-center flex z-[102]"
    >
      {children}
    </div>
  );
};

export default DrawerCloseButtonMobile;
