const StyledEditInput = ({ onChange, value }) => {
  return (
    <input
      onChange={onChange}
      value={value}
      type="text"
      style={{ fontSize: "clamp(12px, 1.5vw, 16px)" }}
      className="box-border border-transparent border-2 appearance-none rounded-lg h-full w-full p-[8px] font-normal shadow-sm focus:outline-2 focus:outline focus:outline-transparent focus:outline-offset-2 focus:border-transparent"
    />
  );
};

export default StyledEditInput;
