const OpenDrawerLogo = () => {
  return (
    <svg
      width="28"
      height="19"
      viewBox="0 0 28 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_i_1242_25583)">
        <path
          d="M8.99708 16.026C5.1377 16.0148 2.01483 12.8736 2.02602 9.0058C2.03721 5.138 5.1782 2.0149 9.03758 2.02609C12.897 2.03727 16.0198 5.17853 16.0086 9.04632C15.9975 12.9141 12.8565 16.0372 8.99708 16.026Z"
          stroke="#FFD668"
          strokeWidth="4"
        />
      </g>
      <g filter="url(#filter1_i_1242_25583)">
        <circle
          cx="9"
          cy="9"
          r="7"
          transform="matrix(-0.00289258 0.999996 -0.999996 -0.00289815 27.9961 0.0521545)"
          stroke="#C424A3"
          strokeWidth="4"
        />
      </g>
      <defs>
        <filter
          id="filter0_i_1242_25583"
          x="0.0258789"
          y="0.026062"
          width="17.9829"
          height="20"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="2" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
          />
          <feBlend
            mode="normal"
            in2="shape"
            result="effect1_innerShadow_1242_25583"
          />
        </filter>
        <filter
          id="filter1_i_1242_25583"
          x="9.97021"
          y="0.0260315"
          width="18"
          height="20"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="2" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
          />
          <feBlend
            mode="normal"
            in2="shape"
            result="effect1_innerShadow_1242_25583"
          />
        </filter>
      </defs>
    </svg>
  );
};

export default OpenDrawerLogo;
