const DrawerCloseButton = ({ open, onClick, children }) => {
  return (
    <div
      onClick={onClick}
      className={`cursor-pointer fixed h-[60px] rounded-full w-[80px] top-[140px] bg-[#F4F2FB] hidden lg:flex items-center pl-[0.5rem] left-[calc(100vw-45px)] ${
        open ? "!left-[calc(50vw-40px)] z-[89]" : ""
      }`}
      style={{
        transition: "left 500ms",
        boxShadow:
          "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,rgba(27, 31, 35, 0.1) 0px 0px 0px 1px",
      }}
    >
      {children}
    </div>
  );
};

export default DrawerCloseButton;
