import Button from "../Button/index";
import {
  CancelLogo,
  SaveLogo,
  EditLogo,
  CopyLogo,
  MyQRCodeIcon,
  OtherFeatureIcon,
  AnalyticLogo,
} from "./Logos";
import { useState } from "react";
import { useAuthContext } from "../../../context/AuthContext/AuthContext";
import { useClipboard } from "@chakra-ui/react";
import axios from "axios";
import { useBackdropContext } from "../../../context/BackdropContext/BackdropContext";
import { useDrawerContext } from "../../../context/DrawerContext/DrawerContext";
import { AuthStatus } from "../../../context/AuthContext/AuthContext";
import useCustomToast from "../../utils/useCustomToast";
import CardWrapper from "./components/URLCardComponents/CardWrapper";
import CardHeader from "./components/URLCardComponents/CardHeader";
import DateWrapper from "./components/URLCardComponents/DateWrapper";
import ShortLinkWrapper from "./components/URLCardComponents/ShortLinkWrapper";
import LongLinkWrapper from "./components/URLCardComponents/LonkLinkWrapper";
import VisitorCountWrapper from "./components/URLCardComponents/VisitorCountWrapper";
import CountWrapper from "./components/URLCardComponents/CountWrapper";
import EditLinkWrapper from "./components/URLCardComponents/EditLinkWrapper";
import StyledEditInput from "./components/URLCardComponents/StyledEditInput";
import { useStateManagementContext } from "../../../context/StateManagementContext/StateManagementContext";
import { useRouter } from "next/router";
import { useMixpanelClient } from "../../../hooks/useMixpanelClient";

const URLCard = ({ data }) => {
  const [submitting, setSubmitting] = useState(false);
  const { refreshUrls, setUrls, setDrawerOpened, setFeatureDrawerOpened } =
    useDrawerContext();
  const { user, authenticate } = useAuthContext();
  const [editing, setEditing] = useState(false);
  const [shortUrl, setShortUrl] = useState(data.shorten);
  const [url, setUrl] = useState(data.url);
  const { onCopy } = useClipboard(`ristek.link/${shortUrl}`);
  const toast = useCustomToast();
  const { setBackdropActive } = useBackdropContext();
  const { setState, setDataLink } = useStateManagementContext();
  const router = useRouter();

  const secondsToDate = (seconds) => {
    const date = new Date(1970, 0, 1);
    date.setSeconds(seconds);

    const year = date.getFullYear();
    const month = date.toLocaleString("default", { month: "short" });
    const day = date.getDate();

    return `${month} ${day}, ${year}`;
  };

  const handleCopy = () => {
    onCopy();
    useMixpanelClient.track("copy_url", {
      "is_logged_in": user ? "True" : "False"
    })
    toast({
      title: "Copied to clipboard.",
      message: `ristek.link/${shortUrl} has been copied to your clipboard.`,
      type: "info",
    });
  };

  const shortenedUrlChangeHandler = (e) => {
    const inputUrl = e.currentTarget.value;
    setShortUrl(
      inputUrl.split(" ").length > 1 ? inputUrl.split(" ").join("-") : inputUrl
    );
  };

  const urlChangeHandler = (e) => {
    setUrl(e.currentTarget.value);
  };

  const cancelEdit = () => {
    setEditing(!editing);
    setShortUrl(data.shorten);
    setUrl(data.url);
    useMixpanelClient.track("edit_url")
  };

  const validateInput = () => {
    const urlRegexPattern =
      /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi;
    const regex = new RegExp(urlRegexPattern);

    // do not submit if nothing changed
    if (data.url === url && data.shorten === shortUrl) {
      cancelEdit();
      return false;
    }

    if (url.match(regex) && url.split(" ").length === 1) {
      if (shortUrl.length) {
        return true;
      } else {
        toast({
          title: "Invalid format",
          message: "Your short URL cannot be empty.",
          type: "error",
        });
        return false;
      }
    } else {
      toast({
        title: "Invalid format",
        message: "Your long URL is not a valid URL",
        type: "error",
      });
      return false;
    }
  };

  const saveEdit = async () => {
    setSubmitting(true);
    const authStatus = await authenticate();
    if (authStatus !== AuthStatus.AUTHENTICATED) {
      setEditing(false);
      setSubmitting(false);
      setUrls([]);
      return;
    } else {
      if (validateInput()) {
        // do not send shortUrl if it didn't change
        const payload =
          shortUrl !== data.shorten
            ? {
                id: data.id,
                url: url,
                shorten: shortUrl,
              }
            : {
                id: data.id,
                url: url,
              };
        await axios
          .put("/api/shorten", payload)
          .then((res) => res.data.message)
          .then(async (message) => {
            toast({
              title: "Success!",
              message: `Your link has been successfully updated.`,
              type: "success",
            });
            await refreshUrls();
            setEditing(!editing);
          })
          .catch((err) => {
            toast({
              title: "Error",
              message: err.response.data.message,
              type: "error",
            });
          });
      }
    }
    setSubmitting(false);
  };

  return (
    <CardWrapper editing={editing}>
      <CardHeader>
        <DateWrapper>{secondsToDate(data.createdDate._seconds)}</DateWrapper>
        <div className="flex h-full gap-4 lg:gap-2 xlg:gap-4">
          {editing ? (
            <>
              <Button
                onClick={cancelEdit}
                disabled={submitting}
                type={submitting ? "dead" : "cancel"}
                additionStyle="md:pr-2 md:pl-2 pr-2 pl-2 sm:pl-1 sm:pr-1"
              >
                <CancelLogo size={"clamp(10px, 2vw, 16px)"} />
                <span style={{ marginLeft: "6px" }}>Cancel</span>
              </Button>
              <Button
                onClick={saveEdit}
                disabled={submitting}
                type={submitting ? "dead" : "active"}
                additionStyle="md:pr-2 md:pl-2 pr-2 pl-2 sm:pl-1 sm:pr-1"
              >
                <SaveLogo size={"clamp(10px, 2vw, 16px)"} />
                <span style={{ marginLeft: "5px" }}>Save</span>
              </Button>
            </>
          ) : (
            <>
              <div className="relative h-full group cursor-pointer text-[14px]">
                <Button
                  onClick={
                    user
                      ? () => {
                          router.push("/");
                          setState("generate-qr");
                          setDrawerOpened(false);
                          setDataLink(data);
                          useMixpanelClient.track("open_my_qr")
                        }
                      : () => {useMixpanelClient.track("open_my_qr")}
                  }
                  type={user ? "active" : "dead"}
                  disabled={!user}
                  additionStyle="md:pr-2 md:pl-2 pr-2 pl-2 sm:pl-1 sm:pr-1 hidden lg:flex"
                >
                  <MyQRCodeIcon
                    color={user ? "#5038BC" : "rgb(156, 163, 175)"}
                    size={"clamp(10px, 2vw, 16px)"}
                  />
                  <span
                    style={{ marginLeft: "3px" }}
                    className="truncate lg:text-[11px] xlg:text-[14px]"
                  >
                    My QR Code
                  </span>
                </Button>
                {!user && (
                  <>
                    <div className="w-[10px] h-[10px] hidden absolute group-hover:block border-2 border-[#5038BC22] rotate-45 sm:left-1 -top-[13px]"></div>
                    <div className="hidden group-hover:block absolute w-[200px] p-2 bg-white border-2 border-[#5038BC22] rounded-md -top-[70px] right-0 sm:left-0">
                      You need to login to use this feature
                    </div>
                  </>
                )}
              </div>
              <div className="relative h-full group cursor-pointer text-[14px]">
                <Button
                  onClick={
                    user
                      ? () => {
                          router.push("/");
                          setState("analytic");
                          setDrawerOpened(false);
                          setDataLink(data);
                        }
                      : () => {}
                  }
                  type={user ? "active" : "dead"}
                  disabled={!user}
                  additionStyle="md:pr-2 md:pl-2 pr-2 pl-2 sm:pl-1 sm:pr-1 hidden lg:flex"
                >
                  <AnalyticLogo
                    color={user ? "#5038BC" : "rgb(156, 163, 175)"}
                    size={"clamp(10px, 2vw, 16px)"}
                  />
                  <span
                    style={{ marginLeft: "3px" }}
                    className="truncate lg:text-[11px] xlg:text-[14px]"
                  >
                    Analytic
                  </span>
                </Button>
                {!user && (
                  <>
                    <div className="w-[10px] h-[10px] hidden absolute group-hover:block border-2 border-[#5038BC22] rotate-45 sm:right-1 -top-[13px]"></div>
                    <div className="hidden group-hover:block absolute w-[200px] p-2 bg-white border-2 border-[#5038BC22] rounded-md -top-[70px] right-0 sm:right-0">
                      You need to login to use this feature
                    </div>
                  </>
                )}
              </div>
              <div className="relative h-full group cursor-pointer text-[14px]">
                <Button
                  onClick={user ? cancelEdit : () => {useMixpanelClient.track("edit_url")}}
                  type={user ? "active" : "dead"}
                  disabled={!user}
                  additionStyle="md:pr-2 md:pl-2 pr-2 pl-2 sm:pl-1 sm:pr-1"
                >
                  <EditLogo
                    color={user ? "#5038BC" : "rgb(156, 163, 175)"}
                    size={"clamp(10px, 2vw, 16px)"}
                  />
                  <span
                    style={{ marginLeft: "3px" }}
                    className="truncate lg:text-[11px] xlg:text-[14px]"
                  >
                    Edit
                  </span>
                </Button>
                {!user && (
                  <>
                    <div className="w-[10px] h-[10px] hidden absolute group-hover:block border-2 border-[#5038BC22] rotate-45 right-1 sm:right-1 -top-[13px]"></div>
                    <div className="hidden group-hover:block absolute w-[200px] p-2 bg-white border-2 border-[#5038BC22] rounded-md -top-[70px] right-0 sm:right-0">
                      You need to login to use this feature
                    </div>
                  </>
                )}
              </div>
              <div className="relative h-full group cursor-pointer text-[14px]">
                <Button
                  onClick={
                    user
                      ? () => {
                          setDataLink(data);
                          setBackdropActive(true);
                          setFeatureDrawerOpened(true);
                        }
                      : () => {}
                  }
                  type={user ? "active" : "dead"}
                  disabled={!user}
                  additionStyle="md:pr-2 md:pl-2 pr-2 pl-2 sm:pl-1 sm:pr-1 lg:hidden bg-transparent border-transparent"
                >
                  <OtherFeatureIcon
                    color={user ? "#5038BC" : "rgb(156, 163, 175)"}
                    size={"clamp(12px, 2vw, 16px)"}
                  />
                </Button>
                {!user && (
                  <>
                    <div className="w-[10px] h-[10px] hidden absolute group-hover:block border-2 border-[#5038BC22] rotate-45 sm:right-1 -top-[13px]"></div>
                    <div className="hidden group-hover:block absolute w-[200px] p-2 bg-white border-2 border-[#5038BC22] rounded-md -top-[70px] right-0 sm:right-0">
                      You need to login to use this feature
                    </div>
                  </>
                )}
              </div>
            </>
          )}
        </div>
      </CardHeader>
      {editing ? (
        <>
          <EditLinkWrapper>
            <span
              style={{
                marginRight: "clamp(5px, 0.5vw, 8px)",
                fontSize: "clamp(16px, 3vw, 26px)",
              }}
              className="text-[#5038bc] font-bold"
            >
              ristek.link/
            </span>
            <StyledEditInput
              onChange={shortenedUrlChangeHandler}
              value={shortUrl}
            />
          </EditLinkWrapper>
          <EditLinkWrapper additionalStyles="!mt-[4px]">
            <StyledEditInput onChange={urlChangeHandler} value={url} />
          </EditLinkWrapper>
        </>
      ) : (
        <>
          <ShortLinkWrapper>
            <span className="block truncate max-w-[calc(100%-25px)]">
              ristek.link/{data.shorten}
            </span>
            <CopyLogo onClick={handleCopy} />
          </ShortLinkWrapper>
          <LongLinkWrapper>
            <span className="block truncate xxs:overflow-visible xxs:whitespace-normal">
              {data.url}
            </span>
          </LongLinkWrapper>
        </>
      )}
      {user && (
        <VisitorCountWrapper>
          <CountWrapper additionalStyles="before:bg-[#C424A3]">
            {data.click} clicks
          </CountWrapper>
          <CountWrapper additionalStyles="before:bg-[#64E6FB]">
            {data.uniqueClick} unique visitors
          </CountWrapper>
        </VisitorCountWrapper>
      )}
    </CardWrapper>
  );
};

export default URLCard;
