const LongLinkWrapper = ({ children }) => {
  return (
    <div
      style={{ fontSize: "clamp(12px, 1.5vw, 16px)" }}
      className="text-[#000] max-w-[80%]"
    >
      {children}
    </div>
  );
};

export default LongLinkWrapper;
