const CardWrapper = ({ editing, children }) => {
  return (
    <div
      className={`flex flex-col items-start w-full h-fit rounded-lg mb-8 md:py-4 md:px-6 p-4 bg-white ${editing && "bg-[#f8f8f8]"}`}
      style={{ boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px" }}
    >
      {children}
    </div>
  );
};

export default CardWrapper;
