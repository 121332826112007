const CountWrapper = ({ additionalStyles, children }) => {
  return (
    <div
      style={{
        paddingLeft: "clamp(15px, 2vw, 20px)",
        fontSize: "clamp(10px, 1.5vw, 16px)",
      }}
      className={`mr-4 relative text-dyanmic-xxs text-[#7a7a7a] before:content-[''] before:top-1/2 before:left-0 before:-translate-y-1/2 before:absolute before:h-dynamic before:w-dynamic md:before:rounded before:rounded-sm ${additionalStyles}`}
    >
      {children}
    </div>
  );
};

export default CountWrapper;
