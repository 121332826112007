
const DrawerSearchBar = ({ onKeyUp, onChange, placeholder }) => {
  return (
    <input
      className="w-full h-8 bg-[#f5f5f5] box-border rounded-lg p-[20px] font-medium focus:outline-0"
      onKeyUp={onKeyUp}
      onChange={onChange}
      placeholder={placeholder}
    />
  );
};

export default DrawerSearchBar
