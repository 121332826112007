const ShortLinkWrapper = ({ children }) => {
  return (
    <div
      style={{ fontSize: "clamp(16px, 3vw, 26px)" }}
      className="flex pt-[8px] pb-[4px] items-center justify-start text-[#5038bc] font-bold w-[80%] relative"
    >
      {children}
    </div>
  );
};

export default ShortLinkWrapper;
