const StyledDrawer = ({ open, children }) => {
  return (
    <div
      className={`px-0 h-full z-[89] w-[100vw] lg:w-[50vw] lg:h-[calc(100%-5rem)] fixed lg:z-[92] overflow-y-scroll overflow-x-hidden pt-8 pb-0 lg:px-8 box-border bg-white block lg:fixed lg:-right-[50vw] lg:bottom-0 ${
        open
          ? "top-0 absolute lg:top-auto lg:right-0"
          : "top-full lg:top-auto lg:-right-[50vw] animate-disappear lg:animate-none opacity-0 lg:opacity-100"
      }`}
      style={{
        boxShadow:
          "rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,rgba(0, 0, 0, 0.3) 0px 1px 3px -1px",
        transition: "right 500ms, top 500ms ease-in-out",
        msOverflowStyle: "none",
        scrollbarWidth: "none",
      }}
    >
      {children}
    </div>
  );
};

export default StyledDrawer;
