const DateWrapper = ({ children }) => {
  return (
    <div
      className="text-gray-500 font-medium"
      style={{ fontSize: "clamp(11px, 2vw, 14px)" }}
    >
      {children}
    </div>
  );
};

export default DateWrapper;
