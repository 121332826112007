const MobileDrawerOpener = ({ onClick, children }) => {
  return (
    <a
      href="#__next"
      onClick={onClick}
      className="lg:hidden flex absolute top-[90%] left-1/2 -translate-x-1/2 w-[80vw] h-fit box-border bg-[#5038bc] rounded-md justify-between items-center cursor-pointer py-4 px-[0.8rem]"
    >
      {children}
    </a>
  );
};

export default MobileDrawerOpener;
